import { useState } from "react";
import { Row, Col, Drawer } from "antd";
import { withTranslation, TFunction } from "react-i18next";
import Container from "../../common/Container";
import { SvgIcon } from "../../common/SvgIcon";

import {
  HeaderSection,
  LogoContainer,
} from "./styles";

const Header = ({ t }: { t: TFunction }) => {
  const [visible, setVisibility] = useState(false);

  const toggleButton = () => {
    setVisibility(!visible);
  };

  return (
    <HeaderSection>
      <Container>
        <Row justify="space-between">
        <LogoContainer to="/" aria-label="homepage">
          <SvgIcon src="favicon.svg" width="30px" height="60px" />
            <SvgIcon src="logo.svg" width="350px" height="60px" />
          </LogoContainer>
        </Row>
      </Container>
    </HeaderSection>
  );
};

export default withTranslation()(Header);
